import { create } from "zustand"
import { UserProfileType } from "../services/CelebUser.Service"
interface LoadingState {
	isLoading: boolean
	showLoading: () => void
	hideLoading: () => void
}

export const userSessionStore = create<UserSessionStoreType>(
	( set ) =>
		<UserSessionStoreType>{
			user: {},
			token: null,
			servicereload: false,
			deleteDialogOpen: { hideShow: false },
			setSession: ( sessionDetails: UserProfileType ) => set( () => ( { user: sessionDetails } ) ),
			setToken: ( token: any ) => set( () => ( { token } ) ),
			logout: () => set( { user: undefined, token: null } ),
			setServiceReload: ( servicereload: any ) => set( () => ( { servicereload } ) ),
			setdeleteDialogOpen: ( next: DeleteDialogType ) => set( () => ( { deleteDialogOpen: next } ) ),
			activeServiceId: "",

			showAlert: {
				status: "",
				message: "",
				visible: false,
			},
			setShowAlert: ( next: keyof UserSessionStoreType["showAlert"], value: any ) =>
				set( ( state ) => ( {
					showAlert: {
						...state.showAlert,
						[ next ]: value,
					},
				} ) ),
			setShowAlertJson: ( next: UserSessionStoreType["showAlert"] ) => set( { showAlert: next } ),

			genericPermissions: {},
			setGenericPermissionsJson: ( next: [] ) => set( { genericPermissions: next } ),
			showToast: {
				status: "Primary",
				message: "",
				visible: false,
			},
			setShowToast: ( next: keyof UserSessionStoreType["showToast"], value: any ) =>
				set( ( state ) => ( {
					showToast: {
						...state.showToast,
						[ next ]: value,
					},
				} ) ),
			setShowToastJson: ( next: UserSessionStoreType["showToast"] ) => set( { showToast: next } ),

			progressLoaderObj: {
				max: 100,
				message: "",
				now: 100,
				show: false,
			},
			setActiveServiceID: ( next: string ) => set( { activeServiceId: next } ),
			setProgressLoaderObj: ( next: keyof UserSessionStoreType["progressLoaderObj"], value: any ) =>
				set( ( state ) => ( {
					progressLoaderObj: {
						...state.progressLoaderObj,
						[ next ]: value,
					},
				} ) ),
			setProgressLoaderJson: ( next: UserSessionStoreType["progressLoaderObj"] ) =>
				set( { progressLoaderObj: next } ),
		},
)

export const useLoadingStore = create<LoadingState>( ( set ) => ( {
	isLoading: false,
	showLoading: () => set( { isLoading: true } ),
	hideLoading: () => set( { isLoading: false } ),
} ) )

interface UserSessionStoreType {
	user: UserProfileType
	token: any
	servicereload: boolean
	deleteDialogOpen: DeleteDialogType
	setSession: ( sessionDetails: UserProfileType ) => void
	setToken: ( token: any ) => void
	logout: () => void
	setServiceReload: ( servicereload: any ) => void
	setdeleteDialogOpen: ( next: DeleteDialogType ) => void
	activeServiceId: string
	setActiveServiceID: ( servicereload: string ) => void
	showAlert: {
		status: string
		message: string
		visible: boolean
	}
	setShowAlert: ( next: keyof UserSessionStoreType["showAlert"], value: any ) => void
	setShowAlertJson: ( next: UserSessionStoreType["showAlert"] ) => void

	showToast: {
		status: "Primary" | "Secondary" | "Success" | "Danger" | "Warning" | "Info" | "Light" | "Dark"
		message: string
		visible: boolean
	}
	setShowToast: ( next: keyof UserSessionStoreType["showToast"], value: any ) => void
	setShowToastJson: ( next: UserSessionStoreType["showToast"] ) => void

	progressLoaderObj: {
		message: string
		max: number
		now: number
		show: boolean
	}

	genericPermissions: {}
	setProgressLoaderObj: ( next: keyof UserSessionStoreType["progressLoaderObj"], value: any ) => void
	setProgressLoaderJson: ( next: UserSessionStoreType["progressLoaderObj"] ) => void
	setGenericPermissionsJson: ( next: [] ) => void
}
export type DeleteDialogType = {
	deletingEntityLabel?: string | number
	handleDelete?: () => void
	hideShow: boolean
}

export type UserSessionType = {
	category: string
	clinicalPreference: string
	firstName: string
	genericPermissions: {}
	genericPermissionsRead: []
	genericPermissionsWrite: []
	id: string
	isAdmin: true
	isDoctor: false
	lastName: string
	lastPermissionUpdatedOn: null
	parentAccountId: null
	profilePictureUri: string
	roleId: string
	specialRole: string
	taskPermissions: {}
	taskPermissionsRead: []
	taskPermissionsWrite: []
}
