import { useSearchParams } from "react-router-dom"
import ToothModel from "../../../assets/components/toothModel"
import { useSidebarStore } from "../../../store/sideBarStore"
import { CheckGenericPermission } from "../../permissions/CheckPermision"
import { infoMapperNames } from "../../utlity/GenricFunctions"
import "./sidebar.css"

const SideInstructions = () => {
	const [ searchParams ] = useSearchParams()
	const patientId = searchParams.get( "PatientId" )
	if ( !patientId ) {
		return <div className="sideBarPatientInfo">No Patient Selected</div>
	}
	const sidebar = useSidebarStore()
	const patientData = sidebar.sideBarPatientData.PatientData || {}
	return (
		<div
			className="sideBarPatientInfo"
			style={{ height: "100%", backgroundColor: "#d9d9d9", width: "100%" }}
		>
			<CheckGenericPermission genericLabel="tx.Instructions : Orthodontist Name">
				<div>
					<span className="label">Orthodontist:</span>{" "}
					<span className="label-data">{infoMapperNames( patientData.doctor, [ "id" ] ) || "N/A"}</span>
				</div>
			</CheckGenericPermission>
			<CheckGenericPermission genericLabel="tx.Instructions : Instructions">
				<div>
					<span className="label">Treatment Instructions:</span>{" "}
					<span className="label-data">{patientData.instruction || "N/A"}</span>
				</div>
			</CheckGenericPermission>
			{/*
			<CheckGenericPermission genericLabel="tx.Instructions : Bonding teeths">
				<ListGroup variant="flush">
					<ListGroup.Item className="listCard mt-3">
						<Button onClick={() => setBondingTeethDetails( true )}>Bonding Teeths Details</Button>
					</ListGroup.Item>
				</ListGroup>
			</CheckGenericPermission>
			<BondingTeethDialog
				patientId={patientId}
				bondingTeethDetails={bondingTeethDetails}
				setBondingTeethDetails={setBondingTeethDetails}
			/> */}
			<CheckGenericPermission genericLabel="tx.Instructions : Bonding teeths">
				<div className="mt-3">
					<span className="label">Bonded Teeths :</span> <ToothModel />
				</div>
			</CheckGenericPermission>
		</div>
	)
}

// const BondingTeethDialog = ( {
// 	patientId,
// 	bondingTeethDetails,
// 	setBondingTeethDetails,
// }: {
// 	patientId: string
// 	bondingTeethDetails: boolean
// 	setBondingTeethDetails: React.Dispatch<React.SetStateAction<boolean>>
// } ) => {
// 	const { data: toothData } = getPatientByIdInstrQuery( patientId || null )
// 	const itterativeCheck = toothData ? { ...toothData.teethBonding } : {}
// 	return (
// 		<Modal show={bondingTeethDetails} size="lg" onHide={() => setBondingTeethDetails( false )}>
// 			<Navbar as={Modal.Header} id="navbarScroll" closeButton>
// 				<Nav variant="tabs" defaultActiveKey="#PatientInfo" className="d-flex mt-2 me-auto">
// 					<Nav.Link active>Bonding Teeths</Nav.Link>
// 				</Nav>
// 			</Navbar>
// 			<Modal.Body>
// 				{
// 					<div>
// 						<span className="label">Bonded Teeths :</span>
// 						<div className="d-grid justify-content-center">
// 							<span
// 								className="d-flex justify-content-between"
// 								style={{ color: "#87ceeb", fontWeight: "bold", fontSize: "12px" }}
// 							>
// 								<p>R</p>
// 								<p>L</p>
// 							</span>
// 							<div className="d-flex justify-content-between w-100">
// 								<div className="d-flex">
// 									{Object.keys( teethIdJSON.upperSection.right )
// 										.reverse()
// 										.map( ( toothId ) => (
// 											<CelebButtonTootheNumber
// 												key={toothId}
// 												tootheNumber={Number( toothId[ 2 ] )}
// 												active={itterativeCheck[ toothId ]}
// 												disabled={itterativeCheck.disabledUpper}
// 											/>
// 										) )}
// 									<div className="vr"></div>
// 									{Object.keys( teethIdJSON.upperSection.left ).map( ( toothId ) => (
// 										<CelebButtonTootheNumber
// 											key={toothId}
// 											tootheNumber={Number( toothId[ 2 ] )}
// 											active={itterativeCheck[ toothId ]}
// 											disabled={itterativeCheck.disabledUpper}
// 										/>
// 									) )}
// 								</div>
// 							</div>
// 							<hr className="hr" />
// 							<div className="d-flex justify-content-between w-100">
// 								<div className="d-flex">
// 									{Object.keys( teethIdJSON.lowerSection.right )
// 										.reverse()
// 										.map( ( toothId ) => (
// 											<CelebButtonTootheNumber
// 												key={toothId}
// 												tootheNumber={Number( toothId[ 2 ] )}
// 												active={itterativeCheck[ toothId ]}
// 												disabled={itterativeCheck.disabledLower}
// 											/>
// 										) )}
// 									<div className="vr"></div>
// 									{Object.keys( teethIdJSON.lowerSection.left ).map( ( toothId ) => (
// 										<CelebButtonTootheNumber
// 											key={toothId}
// 											tootheNumber={Number( toothId[ 2 ] )}
// 											active={itterativeCheck[ toothId ]}
// 											disabled={itterativeCheck.disabledLower}
// 										/>
// 									) )}
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				}
// 			</Modal.Body>
// 		</Modal>
// 	)
// }

export default SideInstructions
